import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./helpers/Global/GCss/index";
import { Route, Routes, useLocation } from "react-router-dom";
import RouterList from "./helpers/Routes/RoutesList";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AlertModal from "./components/AlertModal/AlertModal";

function App() {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const location = useLocation();
  const [isAnimate, setisAnimate] = useState(true);

 
  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  }, []);





  return (
    <>
    <Helmet>

    </Helmet>


    <AlertModal/>

    <Routes>
            {RouterList &&
              RouterList.map((elem, ind) => {
                return (
                  <Route key={ind} path={elem.path} element={elem.element} />
                );
              })}
          </Routes>


    </>
  );
}

export default App;
