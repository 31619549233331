import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import "../../../assets/css/portfolio.css"
import HoverVideoPlayer from 'react-hover-video-player';
import PortfolioMobile from './PortfolioMobile';
import axios from 'axios';
import { PORTFOLIO_LIST, PORTFOLIO_URL, options } from '../../../helpers/apiurls';

import { Player } from 'video-react';
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Fade, Slide } from "react-awesome-reveal";

const Portfolio = () => {
  const [loader, setloader] = useState(true);
  const [portfolioList, setPortfolioList] = useState([]);

  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };


  useEffect(() => {
    portfolio();
    resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  }, []);

  const portfolio = async () => {
    await axios.get(PORTFOLIO_LIST, options).then((res) => {
      if (res && res.status === 200) {
        setPortfolioList(res?.data);
        setloader(false);
      }
    });
  };

  function getFileExtension(filename) {
    return filename.includes('.') ? filename.split('.').pop() : '';
  }


  return (
    <section className='greyGradient1 pt60 pb60 ' id='portfolio'>
      <Container>
        <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>
          <h2 className='fs38 fw700 colorBlack text-center mb60 '>
            Industries We Excel In
          </h2>
        </Fade>
        {
          loader && <Row className="gy-3">
            {
              Array(6).fill().map((e, i) => (
                <Col lg={6} md={6} key={i}>
                  <div className="skeleton" style={{ height: "400px" }}></div>
                </Col>
              ))
            }
          </Row>
        }

        {
          screenWidth < 992 ? <PortfolioMobile data={portfolioList} /> : <>
            {
              portfolioList.length > 0 && portfolioList?.map((e, i) => {

                if (e.type === "video") {
                  return <div className='leftStyle portfolioCommon mb120' key={i}>
                    <Row>
                      <Col lg={6} xs={6} sm={6} md={6}>
                        <div className='zIndex3 position-relative'>
                          <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>
                            <div className='leftImage p14 bgGreyish radius60 position-relative zIndex1'>

                              <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                autoplay={{
                                  delay: 2500,
                                  disableOnInteraction: false,
                                }}
                                pagination={{
                                  type: 'fraction',
                                }}
                                navigation={false}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper1 portfolioSwiper1 radius50"
                              >

                                {
                                  e.pimg?.split(",").length > 0 && e.pimg?.split(",")?.map((el, ind, array) => (
                                    getFileExtension(el.replaceAll(" ", "")) === "mp4" ? <SwiperSlide key={ind}>
                                      <Player
                                        className="w-100" height={350} muted={true} playsInline={true} autoPlay={true} loop={true} controls={false}>
                                        <source src={PORTFOLIO_URL + el.replaceAll(" ", "")} />
                                      </Player>
                                    </SwiperSlide> :
                                      <SwiperSlide key={ind}>
                                        <img src={PORTFOLIO_URL + el.replaceAll(" ", "")} alt="" className='img-fluid radius50' />
                                      </SwiperSlide>
                                  ))

                                }

                              </Swiper>





                            </div>
                          </Fade>
                        </div>


                      </Col>
                      <Col lg={6} xs={12} sm={6} md={6}>

                        <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>
                          <div className='textGrp pt30 pb30'>
                            <Fade bottom>
                              <h3 className='fs38 fw700 colorBlack '>{e?.title}
                              </h3>
                            </Fade>

                            <p className='fs24'>
                              {e?.descp}
                            </p>
                          </div>
                        </Fade>

                        <Fade direction="up" duration={1500} delay={300} triggerOnce={true}>

                          <div className='bgBlue radius50 d-flex justify-content-center align-items-center heightManage'>
                            <div className='logoGroup p-5'>
                              {
                                e?.logo_image.length > 0 && e?.logo_image?.map((el, ind, array) => (
                                  el?.logo !== "" &&
                                  <div className={`${ind === 0 && "bounce"} ${ind !== array.length - 1 ? "mb60" : ""} logoList text-center`} key={ind}>

                                    <Fade bottom triggerOnce={true}>
                                      <a className='' href={el?.url} target="_blank" rel="noopener noreferrer">
                                        <img src={PORTFOLIO_URL + el?.logo} className='img-fluid' alt="" />
                                      </a>
                                    </Fade>

                                  </div>
                                ))
                              }
                              {/* <div className='logoList mb60 text-center bounce'>
                               <Fade bottom triggerOnce={true}>
                                 <a className='' href="https://marqi-woad.vercel.app/" target="_blank" rel="noopener noreferrer">
                                   <img src={require("../../../assets/img/home/portfolio/realstate/MARQI.png")} className='img-fluid' alt="" />
                                 </a>
                               </Fade>

                             </div>
                             <div className='logoList mb60 text-center'>
                               <Fade bottom triggerOnce={true}>
                                 <a href="https://www.adityahomes.com/casa-grande.php" target="_blank" rel="noopener noreferrer">
                                   <img src={require("../../../assets/img/home/portfolio/realstate/Sri Aditya Homes.png")} className='img-fluid' alt="" />
                                 </a>
                               </Fade>

                             </div>
                             <div className='logoList text-center'>
                               <Fade bottom triggerOnce={true}>
                                 <a href="https://trendsetjayabheri.com/elevate/" target="_blank" rel="noopener noreferrer">
                                   <img src={require("../../../assets/img/home/portfolio/realstate/Jayabheri.png")} className='img-fluid' alt="" />
                                 </a>
                               </Fade>

                             </div> */}
                            </div>
                          </div>
                        </Fade>

                      </Col>
                    </Row>
                  </div>
                }

                if (e.type === "right") {
                  return <div className='rightStyle portfolioCommon mb120'>
                    <Row>
                      <Col lg={6}>
                        <Fade direction="up" duration={1200} delay={200} triggerOnce={true}>
                          <div className='textGrp pt30 pb30 text-end'>
                            <Fade bottom triggerOnce={true}>
                              <h3 className='fs38 fw700 colorBlack '>
                                {e?.title}
                              </h3>
                            </Fade>
                            {
                              e?.title2 !== null && <Fade bottom triggerOnce={true}>
                                <h5 className='fs30'>
                                  {e?.title2}
                                </h5>
                              </Fade>
                            }


                            <Fade bottom triggerOnce={true}>
                              <p className='fs24'>
                                {e?.descp}
                              </p>
                            </Fade>

                          </div>
                        </Fade>

                        <Fade direction="up" duration={1500} delay={300} triggerOnce={true}>
                          <div className='bgBlue radius50 d-flex justify-content-center align-items-center heightManageLeft'>

                            <div className='logoGroup p-5'>
                              {
                                e?.summary === null || e?.summary === "" ?
                                  e?.logo_image.length > 0 && e?.logo_image?.map((el, ind, array) => (

                                    el?.logo !== "" &&
                                    <div className={`${ind !== array.length - 1 ? "mb60" : ""} logoList`} key={ind}>
                                      <Fade bottom triggerOnce={true}>
                                        <a href={el?.url} target="_blank" rel="noopener noreferrer">
                                          <img src={PORTFOLIO_URL + el?.logo} className='img-fluid' alt="" />
                                        </a>
                                      </Fade>

                                    </div>
                                  ))
                                  : <Row>
                                    <Col md={7}>
                                      <p className='lightPara fs24'>
                                        {e?.summary}
                                      </p>
                                    </Col>
                                  </Row>
                              }

                              {/* <div className='logoList mb60' >
                              <Fade bottom triggerOnce={true}>
                                <a href="https://www.instagram.com/mego_india" target="_blank" rel="noopener noreferrer">
                                  <img src={require("../../../assets/img/home/portfolio/fmcg/ITC.jpg")} className='img-fluid' alt="" />
                                </a>
                              </Fade>

                            </div>
                            <div className='logoList'>
                              <Fade bottom triggerOnce={true}>
                                <a href="https://www.itcportal.com/brands-microsite/" target="_blank" rel="noopener noreferrer">
                                  <img src={require("../../../assets/img/home/portfolio/fmcg/megologobig.png")} className='img-fluid' alt="" />
                                </a>
                              </Fade>

                            </div> */}
                            </div>
                          </div>
                        </Fade>

                      </Col>
                      <Col lg={6}>
                        <Fade direction="up" duration={1200} delay={200} triggerOnce={true}>
                          <div className='leftImage p14 bgGreyish radius60 zIndex1 position-relative'>

                            <Swiper
                              spaceBetween={30}
                              centeredSlides={true}
                              autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                              pagination={{
                                type: 'fraction',
                              }}
                              navigation={false}
                              modules={[Autoplay, Pagination, Navigation]}
                              className="mySwiper1 portfolioSwiper1 radius50"
                            >

                              {
                                e.pimg?.split(",").length > 0 && e.pimg?.split(",")?.map((el, ind, array) => (
                                  getFileExtension(el.replaceAll(" ", "")) === "mp4" ? <SwiperSlide key={ind}>
                                    <Player
                                      className="w-100" height={350} muted={true} playsInline={true} autoPlay={true} loop={true} controls={false}>
                                      <source src={PORTFOLIO_URL + el.replaceAll(" ", "")} />
                                    </Player>
                                  </SwiperSlide> :
                                    <SwiperSlide key={ind}>
                                      <img src={PORTFOLIO_URL + el.replaceAll(" ", "")} alt="" className='img-fluid radius16' />
                                    </SwiperSlide>
                                ))

                              }
                              {/* <SwiperSlide >
                              <img src={require("../../../assets/img/home/portfolio/fmcg/features/fmcg1.webp")} alt="" className='img-fluid radius50 w-100' />
                            </SwiperSlide>
                            <SwiperSlide >
                              <img src={require("../../../assets/img/home/portfolio/fmcg/features/fmcg2.webp")} alt="" className='img-fluid radius50 w-100' />
                            </SwiperSlide>
                            <SwiperSlide >
                              <img src={require("../../../assets/img/home/portfolio/fmcg/features/fmcg3.webp")} alt="" className='img-fluid radius50 w-100' />
                            </SwiperSlide>
                            <SwiperSlide >
                              <img src={require("../../../assets/img/home/portfolio/fmcg/features/fmcg4.webp")} alt="" className='img-fluid radius50 w-100' />
                            </SwiperSlide> */}
                            </Swiper>


                          </div>
                        </Fade>

                      </Col>

                    </Row>
                  </div>
                }
                if (e.type === "center") {
                  return <Fade direction="up" duration={1200} delay={350} triggerOnce={true}>
                    <div className='leftStyle portfolioCommon mb120'>
                      <div className='bgBlue radius60 d-flex justify-content-center align-items-center'>
                        <Row className='w-100'>
                          <Col lg={6} className='pl0'>
                            <div className='leftImage p14 bgGreyish radius50 position-relative zIndex1'>
                              <div className='position-relative'>
                                <div className='overlayEffect radius50'>
                                </div>
                                {/* {
                                  e.pimg?.split(",").length > 0 && e.pimg?.split(",")?.map((el, ind, array) => (
                                    getFileExtension(el.replaceAll(" ", "")) === "mp4" ? <Player
                                      className="w-100" height={350} muted={true} playsInline={true} autoPlay={true} loop={true} controls={false}>
                                      <source src={PORTFOLIO_URL + el.replaceAll(" ", "")} />
                                    </Player> :
                                      <img src={PORTFOLIO_URL + el.replaceAll(" ", "")} className='img-fluid radius50' key={ind} alt="" />
                                  ))

                                } */}
                                <Swiper
                                  spaceBetween={30}
                                  centeredSlides={true}
                                  autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                  }}
                                  pagination={{
                                    type: 'fraction',
                                  }}
                                  navigation={false}
                                  modules={[Autoplay, Pagination, Navigation]}
                                  className="mySwiper1 portfolioSwiper1 radius50"
                                >

                                  {
                                    e.pimg?.split(",").length > 0 && e.pimg?.split(",")?.map((el, ind, array) => (
                                      getFileExtension(el.replaceAll(" ", "")) === "mp4" ? <SwiperSlide key={ind}>
                                        <Player
                                          className="w-100" height={350} muted={true} playsInline={true} autoPlay={true} loop={true} controls={false}>
                                          <source src={PORTFOLIO_URL + el.replaceAll(" ", "")} />
                                        </Player>
                                      </SwiperSlide> :
                                        <SwiperSlide key={ind}>
                                          <img src={PORTFOLIO_URL + el.replaceAll(" ", "")} alt="" className='img-fluid radius50' />
                                        </SwiperSlide>
                                    ))

                                  }

                                </Swiper>
                                {/* <img src={require("../../../assets/img/home/portfolio/hospitality/features/Hospitality.png")} alt="" className='img-fluid radius50' /> */}
                              </div>
                              <div className='textGrpOverlap pt30 pb30 pl40 pr40 position-absolute'>
                                <Fade bottom triggerOnce={true}>
                                  <h3 className='fs38 fw700 colorWhite'>
                                    {e?.title}
                                  </h3>
                                </Fade>
                                <Fade bottom triggerOnce={true}>
                                  <p className='fs24 colorWhite fw400'>
                                    {e?.descp}
                                  </p>
                                </Fade>

                              </div>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className='bgBlue radius50 d-flex justify-content-center align-items-center h-100'>
                              <div className='logoGroup p-5'>

                                {
                                  e?.logo_image.length > 0 && e?.logo_image?.map((el, ind, array) => (
                                    el?.logo !== "" &&
                                    <div className={`${ind !== array.length - 1 ? "mb30" : ""} logoList`} key={ind}>
                                      <Fade bottom triggerOnce={true}>
                                        <a href={el?.url} target="_blank" rel="noopener noreferrer">
                                          <img src={PORTFOLIO_URL + el?.logo} className='img-fluid' alt="" />
                                        </a>
                                      </Fade>
                                    </div>
                                  ))
                                }
                                {/*                               
                            <div className='logoList mb30'>
                              <Fade bottom triggerOnce={true}>
                                <a href="https://www.instagram.com/toscafecocktails" target="_blank" rel="noopener noreferrer">
                                  <img src={require("../../../assets/img/home/portfolio/hospitality/tos.png")} className='img-fluid' alt="" />
                                </a>
                              </Fade>
  
                            </div>
                            <div className='logoList mb32'>
                              <Fade bottom triggerOnce={true}>
                                <a href="https://www.instagram.com/nohahyd" target="_blank" rel="noopener noreferrer">
                                  <img src={require("../../../assets/img/home/portfolio/hospitality/Noha.jpg")} className='img-fluid' alt="" />
                                </a>
                              </Fade>
                            </div>
                            <div className='logoList'>
                              <Fade bottom triggerOnce={true}>
                                <a href="https://www.instagram.com/aromaofdakshin" target="_blank" rel="noopener noreferrer">
                                  <img src={require("../../../assets/img/home/portfolio/hospitality/aroma.png")} className='img-fluid' alt="" />
                                </a>
                              </Fade>
                            </div> */}



                              </div>
                            </div>

                          </Col>
                        </Row>
                      </div>

                    </div>
                  </Fade>
                }
                if (e.type === "left") {
                  return <div className='leftStyle portfolioCommon mb120'>
                    <Row>
                      <Col lg={6}>
                        <div className='zIndex9999 position-relative'>
                          <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>

                            <div className='leftImage p14 bgGreyish radius60 position-relative zIndex1 '>
                              <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                autoplay={{
                                  delay: 2500,
                                  disableOnInteraction: false,
                                }}
                                pagination={{
                                  type: 'fraction',
                                }}
                                navigation={false}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper1 portfolioSwiper1 radius50"
                              >

                                {
                                  e.pimg?.split(",").length > 0 && e.pimg?.split(",")?.map((el, ind, array) => (
                                    getFileExtension(el.replaceAll(" ", "")) === "mp4" ? <SwiperSlide key={ind}>
                                      <Player
                                        className="w-100" height={350} muted={true} playsInline={true} autoPlay={true} loop={true} controls={false}>
                                        <source src={PORTFOLIO_URL + el.replaceAll(" ", "")} />
                                      </Player>
                                    </SwiperSlide> :
                                      <SwiperSlide key={ind}>
                                        <img src={PORTFOLIO_URL + el.replaceAll(" ", "")} alt="" className='img-fluid radius50 w-100' />
                                      </SwiperSlide>
                                  ))

                                }
                                    {/* <SwiperSlide >
                              <img src={require("../../../assets/img/home/portfolio/healthcare/features/health1.webp")} alt="" className='img-fluid radius50 w-100' />
                            </SwiperSlide>
                            <SwiperSlide >
                              <img src={require("../../../assets/img/home/portfolio/healthcare/features/health2.webp")} alt="" className='img-fluid radius50 w-100' />
                            </SwiperSlide>
                            <SwiperSlide >
                              <img src={require("../../../assets/img/home/portfolio/healthcare/features/health3.webp")} alt="" className='img-fluid radius50 w-100' />
                            </SwiperSlide>
                            <SwiperSlide >
                              <img src={require("../../../assets/img/home/portfolio/healthcare/features/health4.webp")} alt="" className='img-fluid radius50 w-100' />
                            </SwiperSlide> */}

                              </Swiper>

                        


                            </div>


                          </Fade>
                        </div>

                      </Col>
                      <Col lg={6}>
                        <Fade direction="up" duration={1200} delay={200} triggerOnce={true}>
                          <div className='textGrp pt30 pb30'>
                            <Fade bottom triggerOnce={true}>
                              <h3 className='fs38 fw700 colorBlack '> {e?.title}
                              </h3>
                            </Fade>
                            <Fade bottom triggerOnce={true}>
                              <p className='fs24'>
                                {e?.descp}
                              </p>
                            </Fade>
                          </div>
                        </Fade>

                        <Fade direction="up" duration={1500} delay={300} triggerOnce={true}>
                          <div className='bgBlue radius50 d-flex justify-content-center align-items-center heightManage'>
                            <div className='logoGroup p-5'>

                              {
                                e?.logo_image.length > 0 && e?.logo_image?.map((el, ind, array) => (
                                  el?.logo !== "" &&
                                  <div className={`${ind !== array.length - 1 ? "mb60" : ""} logoList`} key={ind}>
                                    <Fade bottom triggerOnce={true}>
                                      <a href={el?.url} target="_blank" rel="noopener noreferrer">
                                        <img src={PORTFOLIO_URL + el?.logo} className='img-fluid' alt="" />
                                      </a>
                                    </Fade>
                                  </div>
                                ))
                              }
                              {/* <div className='logoList mb60'>
                            <Fade bottom triggerOnce={true}>
                              <a href="https://vasavihospital.in/" target="_blank" rel="noopener noreferrer">
                                <img src={require("../../../assets/img/home/portfolio/healthcare/vasavi.png")} className='img-fluid' alt="" />
                              </a>
                            </Fade>
                          </div>
                          <div className='logoList'>
                            <Fade bottom triggerOnce={true}>
                              <a href="https://vihaaramedicare.com/" target="_blank" rel="noopener noreferrer">
                                <img src={require("../../../assets/img/home/portfolio/healthcare/vihara.png")} className='img-fluid' alt="" />
                              </a>
                            </Fade>
                          </div> */}
                            </div>
                          </div>
                        </Fade>

                      </Col>
                    </Row>
                  </div>
                }
              }



              )

            }








          </>
        }

      </Container>
    </section>
  )
}

export default Portfolio