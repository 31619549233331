import axios from "axios";
import React, { useEffect, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Form } from "reactstrap";
import { CONTACT_FORM, optionsPost } from "../../helpers/apiurls";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
  const [loader, setloader] = useState(false);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [project, setProject] = useState("");
  const [onSuccess, setOnsuccess] = useState("");

  const clearForm=()=>{
    setEmail("")
    setMobile("")
    setProject("")
  }

  const formHandling = async (e) => {
    e.preventDefault();
    const data = {
      email,
      mobile,
      request: project
    };

    setloader(true);
   
    await axios.post(CONTACT_FORM, data,optionsPost).then((res) => {
      if (res && res.status == 200) {
        setOnsuccess(res?.data);
        setloader(false);
        toast.success(res?.data?.message)
        clearForm()
      }else{
        toast.error(res?.data?.message)
        setloader(false);
      }
    });
  };

  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  });

  return (
    <>
     <ToastContainer />
     <section className={`${screenWidth > 767 ? "pt60" : "pt0"}`}>
      <Fade direction="up" duration={1000} delay={200} triggerOnce={true}>
        <h2 className="fs42  pt40 fw700 colorBlack">Contact Us</h2>
      </Fade>

     
        <Form onSubmit={formHandling}>
        <Fade direction="up" duration={1500} delay={100} triggerOnce={true}>
        <div>
        <div className="form-group material-bootstrap-input mb0">
            <input
              type="email"
              className="form-control"
              id="materialInput"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Email ID"
              required
            />
            <label htmlFor="materialInput">
              Email ID <span className="colorRed">*</span>
            </label>
          </div>
        </div>
     
          </Fade>

          <Fade direction="up" duration={1500} delay={150} triggerOnce={true}>
            <div>
            <div className="form-group material-bootstrap-input mb0">
            <input
              type="tel"
              className="form-control"
              id="mobilenumber"
              placeholder="Mobile Number"
              onChange={(e) => setMobile(e.target.value)}
              value={mobile}
              required
            />
            <label htmlFor="mobilenumber">
              Mobile Number <span className="colorRed">*</span>
            </label>
          </div>
            </div>
          </Fade>

          <Fade direction="up" duration={1500} delay={150} triggerOnce={true}>
            <div>
            <div className="form-group material-bootstrap-input mb0 selBox position-relative">
            <select
              name=""
              id=""
              className="form-control"
              onChange={(e) => setProject(e.target.value)}
              value={project}
              required
            >
              <option value="New Project">New Project</option>
              <option value="Request for Quote">Request for Quote </option>
              <option value="Get a call back">Get a call back</option>
              <option value="Investor Relations">Investor Relations</option>
            </select>
            <label htmlFor="msg">
              Choose Project <span className="colorRed">*</span>
            </label>
          </div>
   

          <div className="mt20">
            <button className="btnTheme" type="submit">
              {loader ? <div className="d-flex align-items-center"> <span className="mr10">Submitting... </span><div className="spinner-border text-light" role="status">
</div> </div> : "Submit Request"}
            </button>
          </div>
            </div>
          </Fade>

    
        </Form>
   
    </section>
    </>

  );
};

export default ContactForm;
