import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { contactType } from "../../store/slices/UserSlices";
import { Fade, Slide } from "react-awesome-reveal";
import { arrow } from "../../assets/svg/Svg";
import { HashLink } from "react-router-hash-link";

const HomeBanner = () => {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };


  useEffect(() => {
    resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  }, []);

  return (
    <div className="position-relative homeBanner d-flex align-items-center">
      <div className="blackShad position-relative w-100 heightVh100">
        <img
          className="img-fluid w-100 bannerImg w-100 h-100"
          src={require("../../assets/img/home/main_banner.png")}
          alt="banner"
        />

        {
          screenWidth > 992 &&

          <div className="w-100 text-center position-absolute h-100 linkBanner bounce">
            <HashLink className="noBtn" to="/#about">
              {arrow}
            </HashLink>
          </div>


        }
      </div>

      <div className="caption position-absolute w-100">
        <div className="container-xl">
          <Row>
            <Col lg={10} md={10} className="m-auto">
              <div className="text-center">
                <Fade direction="up" duration={1000} triggerOnce={true}>
                  <h2 className="bannerF fs70 mb0  colorWhite">
                    <span>Welcome to</span>
                  </h2>
                </Fade>
                <Fade direction="up" duration={1000} delay={200} triggerOnce={true}>
                  <h2 className="bannerF fs70 mb0 colorWhite">
                    <span className="d-block">Archana Didige Group</span>
                  </h2>
                </Fade>

                <Fade direction="up" duration={1000} delay={500} triggerOnce={true}>
                  <Row className="justify-content-center mt30">
                    <Col lg={9}>
                      <p className="fs30 colorWhite">
                        We Transform your spaces into a distinctive brand that captures heart and minds.
                      </p>
                    </Col>
                  </Row>
                </Fade>


              </div>
            </Col>
          </Row>
        </div>
      </div>


    </div>
  );
};

export default HomeBanner;
