import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Fade, Slide } from "react-awesome-reveal";
// import linkdin from "../../../../"
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";

let data = [
  {
    img: require("../../assets/img/home/about/jayanth.png"),
    name: "Jayanth Didige",
    position: "Chairman & Managing Director",
    link: "",
    delay: 100,
  },
  {
    img: require("../../assets/img/home/about/archana_didige.png"),
    name: "Archana Didige",
    position: "Director",
    link: "",
    delay: 200,
  },
  {
    img: require("../../assets/img/home/about/mrinal.png"),
    name: "Mrinal Didige",
    position: "Director",
    link: "https://www.linkedin.com/in/mrinal-didige-1834431a/",
    delay: 300,
  },
  {
    img: require("../../assets/img/home/about/rohit_didige.png"),
    name: "Rohit Didige",
    position: "Director",
    link: "https://www.linkedin.com/in/rohit-didige-34546832/",
    delay: 400,
  },
  {
    img: require("../../assets/img/home/about/anusha.png"),
    name: "Anusha Didige",
    position: "Director",
    link: "https://www.linkedin.com/in/didige-anusha-1b538bba/",
    delay: 500,
  },
];

const AboutUs = () => {
  const [loader, setloader] = useState(true);
  const [portfolioList, setPortfolioList] = useState([]);

  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  }, []);

  return (
    <section className="pt80 pb80 bgWhite" id="about">
      <Container>
        <div className="text-center">
          <Row className="justify-content-center mb20">
            <Col lg={9}>
              <Fade direction="up" duration={1000} triggerOnce={true}>
                <h3 className={`mb15 fontlight fw700 fs38`}>
                  About Didige Group
                </h3>
              </Fade>
              <Fade direction="up" duration={1000} delay={200} triggerOnce={true}>
                <p className={`fw400 lh30 fs24`}>
                  Archana Didige Group stands as a testament to the vision and
                  dedication of a family of seasoned business owners. Educated
                  and well-established, our journey is rooted in a passion for
                  innovation, a commitment to quality, and a relentless pursuit
                  of excellence.
                </p>
              </Fade>
            </Col>
          </Row>

          {screenWidth < 991 ? (
              <Swiper
                slidesPerView={5}
                centeredSlides={false}
                spaceBetween={15}
                autoPlay={{ delay: 800 }}
                speed={800}
                loop={false}
                navigation={false}
                mousewheel={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay, Navigation]}
                breakpoints={{
                  // when window width is >= 640px
                  300: {
                    width: 300,
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  550: {
                    width: 550,
                    slidesPerView: 3,
                  },
                  992: {
                    width: 992,
                    slidesPerView: 4,
                  },
                  1201: {
                    width: 1201,
                    slidesPerView: 5,
                  },
                  1360: {
                    width: 1360,
                    slidesPerView: 5,
                  },
                  1500: {
                    width: 1500,
                    slidesPerView: 5,
                  },
                }}
                className="mySwiper"
              >
                {data.map((e, i) => (
                 
                  <SwiperSlide key={i}>
                    <div className="">
                        <div className="mb13">
                    
                          <a
                            href= {e.link ? e.link : "javscript:void(0)"}
                            target={e.link ? "_blank" : "_self"}
                            rel="noopener noreferrer"
                          >
                            <img
                              src={e.img}
                              alt="building"
                              className="img-fluid radius30"
                            />
                          </a>
                        
                        </div>
                        <div className="text-start">
                          <h4 className="text-uppercase fs20 mb3 fw800">
                            <a
                              className="transition colorBlack"
                              href= {e.link ? e.link : "javscript:void(0)"}
                              target={e.link ? "_blank" : "_self"}
                              rel="noopener noreferrer"
                            >
                              {e.name}  
                            </a>
                          </h4>
                          <p className="colorPara fs16 fs500 mb0">{e.position}</p>
                          {
                          e.link &&   <a
                          href= {e.link ? e.link : "javscript:void(0)"}
                          target={e.link ? "_blank" : "_self"}
                          rel="noopener noreferrer"
                        ><img className="" src={require("../../assets/img/home/about/linkedIn_logo.svg").default} alt="" /> </a>
                        }
                        </div>
                    </div>
                  </SwiperSlide>
                  
                ))}
              </Swiper>
          ) : (
            <div className="row show-grid">
              {data.map((e, i) => (
                <div className="col-sm-6 col-md-1-5 col-lg-1-5" key={i}>
                <Fade direction="up" duration={1000} delay={e?.delay} triggerOnce={true}>
                <div className="aboutWrapper">
                    <div className="mb15">
                      <a
                        className="colorBlack"
                        href= {e.link ? e.link : "javscript:void(0)"}
                        target={e.link ? "_blank" : "_self"}
                        rel="noopener noreferrer"
                      >
                        <img
                          src={e.img}
                          alt="building"
                          className="img-fluid radius30"
                        />
                      </a>
                    </div>
                    <div className="text-start">
                      <h4 className="text-uppercase fs20 mb3 fw800">
                        <a
                          className="colorBlack transition"
                          href= {e.link ? e.link : "javscript:void(0)"}
                        target={e.link ? "_blank" : "_self"}
                          rel="noopener noreferrer"
                        >
                          {e.name}  {
                          e.link &&  <img className="ml4 linkdIcon" src={require("../../assets/img/home/about/linkedIn_logo.svg").default} alt="" />
                        }
                        </a>
                      
                      </h4>
                      <p className="colorPara fs16 fs500">{e.position}</p>
                    </div>
                  </div>
                </Fade>
          
                </div>
              ))}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default AboutUs;
