import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "../../assets/css/header.css";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { Fade } from "react-reveal";

const Header = () => {
  const [isAnimate, setisAnimate] = useState(true);

  useEffect(() => {
    setisAnimate(true);
    setTimeout(() => {
      setisAnimate(false);
    }, 7000);
  }, [window.location.pathname]);

  const [stickyHeader, setstickyHeader] = useState(false);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY;
      if (isTop > 200) {
        setstickyHeader(true);
      } else {
        setstickyHeader(false);
      }
    });
    setstickyHeader(false);
  }, [pathname]);

  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  });

  return (
    <>
      {stickyHeader ? (
        <Fade top  delay={0}>
        <header className="stickyHeader pt10 pb10" id="header">
          <Container>
            <Row>
              <Col lg={4} md={3} sm={3} xs={3}>
              <div className="logoWrapper">
                  <Link className="mainLogo" to="/">
                    <img
                      className="img-fluid mainLogo"
                      src={require("../../assets/img/logo.png")}
                      alt="logo"
                    />
                  </Link>
                </div>
              </Col>
              <Col lg={8} md={9} sm={9} xs={9}>
              <div className="d-flex align-items-center h-100">
              <Navigations sticky={true}/>
              </div>
         
              </Col>
            </Row>
          </Container>
        </header>
        </Fade>
      ) : (
        <header className="position-absolute w-100 headerStatic" id="header">
          <Container>
            <div className="text-center d-flex justify-content-center">
              <div className="inner position-relative">
                <div className="logoWrapper mb15">
                  <Link className="mainLogo" to="/">
                    <img
                      className="img-fluid mainLogo"
                      src={require("../../assets/img/white_logo.png")}
                      alt="logo"
                    />
                  </Link>
                </div>
                <Navigations sticky={false}/>
              </div>
            </div>
          </Container>
        </header>
      )}
    </>
  );
};

export default Header;



const Navigations=(props)=>{
  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth"
    }); 
  }

  const {sticky}=props;
  return <nav className="nav">
  <ul className="noUl d-flex flex-wrap mb0">
    <li className="mr40" onClick={()=>window.scrollTo(0,0)}>
      <HashLink smooth className={`fill-bottom-right ${sticky ? "colorBlack" : "colorWhite"} fs20 fw700`} to="#header">
        Home
      </HashLink>
    </li>
    <li className="mr40">
      <HashLink smooth className={`fill-bottom-right ${sticky ? "colorBlack" : "colorWhite"} fs20 fw700`} to="/#about" scroll={el => scrollWithOffset(el,30)}>
        About
      </HashLink>
    </li>
    <li className="mr40">
      <HashLink smooth
        className={`fill-bottom-right ${sticky ? "colorBlack" : "colorWhite"} fs20 fw700`}
        to="/#portfolio"
        scroll={el => scrollWithOffset(el, 50)}
      >
        Portfolio
      </HashLink>
    </li>
    <li className="">
      <HashLink smooth
        className={`fill-bottom-right ${sticky ? "colorBlack" : "colorWhite"} fs20 fw700`}
        to="/#partners"
        scroll={el => scrollWithOffset(el, 50)}
      >
        Partners
      </HashLink>
    </li>
  </ul>
</nav>
}