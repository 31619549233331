import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Player } from 'video-react';
import { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Fade, Slide } from "react-awesome-reveal";
import { PORTFOLIO_URL } from '../../../helpers/apiurls';
const PortfolioMobile = ({ data }) => {
    function getFileExtension(filename) {
        return filename.includes('.') ? filename.split('.').pop() : '';
    }

    return (
        <div className='mobileWrapper'>
            {
                data.length > 0 && data?.map((e, i) => {
                    if (e.type === "video") {
                        return <div className='mobView mb60' key={i}>
                            <Row className='gx-2'>
                                <Col xs={6}>

                                    <div className='leftImage p5 pb-0 bgGreyish radius16 zIndex1 position-relative'>
                                        <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>

                                            <Swiper
                                                spaceBetween={30}
                                                centeredSlides={true}
                                                autoplay={{
                                                    delay: 2500,
                                                    disableOnInteraction: false,
                                                }}
                                                pagination={{
                                                    type: 'fraction',
                                                }}
                                                navigation={false}
                                                modules={[Autoplay, Pagination, Navigation]}
                                                className="mySwiper portfolioSwiper radius16"
                                            >

                                                {
                                                    e.pimg?.split(",").length > 0 && e.pimg?.split(",")?.map((el, ind, array) => (
                                                        getFileExtension(el.replaceAll(" ", "")) === "mp4" ? <SwiperSlide key={ind}>
                                                            <Player
                                                                className="w-100" height={350} muted={true} playsInline={true} autoPlay={true} loop={true} controls={false}>
                                                                <source src={PORTFOLIO_URL + el.replaceAll(" ", "")} />
                                                            </Player>
                                                        </SwiperSlide> :
                                                            <SwiperSlide key={ind}>
                                                                <img src={PORTFOLIO_URL + el.replaceAll(" ", "")} alt="" className='img-fluid radius16' />
                                                            </SwiperSlide>
                                                    ))

                                                }

                                            </Swiper>



                                        </Fade>
                                    </div>


                                </Col>

                                <Col xs={6}>
                                    <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>
                                        <Fade bottom>
                                            <h3 className='fs20 fw700 colorBlack '>{e?.title}
                                            </h3>
                                        </Fade>
                                        <p className='fs12'>
                                            {e?.descp}
                                        </p>
                                    </Fade>

                                </Col>
                            </Row>
                            <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>
                                <div className='bgBlue radius20 bgBlueMobView d-flex align-items-center justify-content-center'>
                                    <div className='logoGroup pl10 pr10 d-flex justify-content-center align-items-center'>
                                        {
                                            e?.logo_image.length > 0 && e?.logo_image?.map((el, ind, array) => (
                                                el?.logo !== "" &&
                                                <div className={`${ind === 0 && "bounce"} logoList text-center`} key={ind}>
                                                    <Fade bottom triggerOnce={true}>
                                                        <a href={el?.url} target="_blank" rel="noopener noreferrer">
                                                            <img src={PORTFOLIO_URL + el?.logo} className='img-fluid' alt="" />
                                                        </a>
                                                    </Fade>

                                                </div>
                                            ))

                                        }
                                        {/* <div className='logoList text-center bounce'>
                                        <Fade bottom triggerOnce={true}>
                                            <a href="https://marqi-woad.vercel.app/" target="_blank" rel="noopener noreferrer">
                                                <img src={require("../../../assets/img/home/portfolio/realstate/MARQI.png")} className='img-fluid' alt="" />
                                            </a>
                                        </Fade>
        
                                    </div>
                                    <div className='logoList text-center'>
                                        <Fade bottom triggerOnce={true}>
                                            <a href="https://www.adityahomes.com/casa-grande.php" target="_blank" rel="noopener noreferrer">
                                                <img src={require("../../../assets/img/home/portfolio/realstate/Sri Aditya Homes.png")} className='img-fluid' alt="" />
                                            </a>
                                        </Fade>
        
                                    </div>
                                    <div className='logoList text-center'>
                                        <Fade bottom triggerOnce={true}>
                                            <a href="https://trendsetjayabheri.com/elevate/" target="_blank" rel="noopener noreferrer">
                                                <img src={require("../../../assets/img/home/portfolio/realstate/Jayabheri.png")} className='img-fluid' alt="" />
                                            </a>
                                        </Fade>
        
                                    </div> */}
                                    </div>
                                </div>
                            </Fade>

                        </div>
                    }
                    if (e.type === "right") {
                        return <div className='mobView mb60'>
                            <Row className='gx-2'>
                                <Col xs={6} sm={6} md={6} lg={6}>
                                    <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>
                                        <div className='text-end'>
                                            <Fade bottom triggerOnce={true}>
                                                <h3 className='fs20 fw700 colorBlack '>  {e?.title}
                                                </h3>
                                            </Fade>
                                            {
                                                e?.title2 !== null && <Fade bottom triggerOnce={true}>
                                                    <h5 className='fs12'>
                                                        {e?.title2}
                                                    </h5>
                                                </Fade>
                                            }

                                            <p className='fs12'>
                                                {e?.descp}
                                            </p>
                                        </div>
                                    </Fade>


                                </Col>
                                <Col xs={6} sm={6} md={6} lg={6}>


                                    <div className='leftImage p5 bgGreyish radius16 zIndex1 position-relative'>
                                        <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>

                                            <Swiper
                                                spaceBetween={30}
                                                centeredSlides={true}
                                                autoplay={{
                                                    delay: 2500,
                                                    disableOnInteraction: false,
                                                }}
                                                pagination={{
                                                    type: 'fraction',
                                                }}
                                                navigation={false}
                                                modules={[Autoplay, Pagination, Navigation]}
                                                className="mySwiper portfolioSwiper radius16"
                                            >

                                                {
                                                    e.pimg?.split(",").length > 0 && e.pimg?.split(",")?.map((el, ind, array) => (
                                                        getFileExtension(el.replaceAll(" ", "")) === "mp4" ? <SwiperSlide key={ind}>
                                                            <Player
                                                                className="w-100" height={350} muted={true} playsInline={true} autoPlay={true} loop={true} controls={false}>
                                                                <source src={PORTFOLIO_URL + el.replaceAll(" ", "")} />
                                                            </Player>
                                                        </SwiperSlide> :
                                                            <SwiperSlide key={ind}>
                                                                <img src={PORTFOLIO_URL + el.replaceAll(" ", "")} alt="" className='img-fluid radius16' />
                                                            </SwiperSlide>
                                                    ))

                                                }
                                                {/* <SwiperSlide>
                                                <img src={require("../../../assets/img/home/portfolio/fmcg/features/fmcg1.webp")} alt="" className='img-fluid radius16' />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img src={require("../../../assets/img/home/portfolio/fmcg/features/fmcg2.webp")} alt="" className='img-fluid radius16' />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img src={require("../../../assets/img/home/portfolio/fmcg/features/fmcg3.webp")} alt="" className='img-fluid radius16' />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img src={require("../../../assets/img/home/portfolio/fmcg/features/fmcg4.webp")} alt="" className='img-fluid radius16' />
                                            </SwiperSlide> */}
                                            </Swiper>


                                        </Fade>
                                    </div>


                                </Col>

                            </Row>

                            <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>
                                <div className='bgBlue radius20 bgBlueMobView d-flex align-items-center justify-content-center'>
                                    <div className='logoGroup pl10 pr10 d-flex justify-content-center align-items-center'>

                                        {
                                            e?.summary === null || e?.summary === "" ?
                                                e?.logo_image.length > 0 && e?.logo_image?.map((el, ind, array) => (
                                                    el?.logo !== "" &&
                                                    <div className={` logoList text-center`} key={ind}>
                                                        <Fade bottom triggerOnce={true}>
                                                            <a href={el?.url} target="_blank" rel="noopener noreferrer">
                                                                <img src={PORTFOLIO_URL + el?.logo} className='img-fluid' alt="" />
                                                            </a>
                                                        </Fade>

                                                    </div>
                                                ))
                                                : <Row>
                                                    <Col md={7}>
                                                        <p className='lightPara fs14'>
                                                            {e?.summary}
                                                        </p>
                                                    </Col>
                                                </Row>
                                        }

                                        {/* <div className='logoList text-center'>
                                        <Fade bottom triggerOnce={true}>
                                            <a href={el?.url} target="_blank" rel="noopener noreferrer">
                                                <img src={require("../../../assets/img/home/portfolio/fmcg/ITC.jpg")} className='img-fluid' alt="" />
                                            </a>
                                        </Fade>
                                    </div>
                                    <div className='logoList text-center'>
                                        <Fade bottom triggerOnce={true} >
                                            <a href="https://www.itcportal.com/brands-microsite/" target="_blank" rel="noopener noreferrer">
                                                <img src={require("../../../assets/img/home/portfolio/fmcg/megologobig.png")} className='img-fluid' alt="" />
                                            </a>
                                        </Fade>
                                    </div> */}
                                    </div>
                                </div>
                            </Fade>

                        </div>
                    }
                    if (e.type === "center") {
                        return <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>
                            <div className='leftStyle portfolioCommon mb60'>
                                <div className='bgBlue radius50 d-flex justify-content-center align-items-center'>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='leftImage p5 bgGreyish radius16 position-relative zIndex1'>
                                                <div className='position-relative'>
                                                    <div className='overlayEffect radius16'>
                                                    </div>

                                        
  { 
                                                        e?.pimg?.split(",").length > 0 && e?.pimg?.split(",")?.map((el, ind, array) => (
                                                            getFileExtension(el.replaceAll(" ", "")) === "mp4" ?
                                                            <Player
                                                            className="w-100" height={350} muted={true} playsInline={true} autoPlay={true} loop={true} controls={false}>
                                                            <source src={PORTFOLIO_URL + el.replaceAll(" ", "")} />
                                                        </Player> :
                                                        <Swiper
                                                        spaceBetween={30}
                                                        centeredSlides={true}
                                                        autoplay={{
                                                            delay: 2500,
                                                            disableOnInteraction: false,
                                                        }}
                                                        pagination={{
                                                            type: 'fraction',
                                                        }}
                                                        navigation={false}
                                                        modules={[Autoplay, Pagination, Navigation]}
                                                        className="mySwiper portfolioSwiper radius16"
                                                        >
                                                        
                                                        {
                                                            e.pimg?.split(",").length > 0 && e.pimg?.split(",")?.map((el, ind, array) => (
                                                             
                                                                    <SwiperSlide key={ind}>
                                                                        <img src={PORTFOLIO_URL + el.replaceAll(" ", "")} alt="" className='img-fluid radius16' />
                                                                    </SwiperSlide>
                                                            ))
                                                        
                                                        }
                                                        
                                                        </Swiper>

                                                            // <img src={PORTFOLIO_URL + el.replaceAll(" ", "")} className='img-fluid radius16' key={ind} alt="" />
                                                        ))
                                                    }
                                          


                                                    {/* {
                                                        e?.pimg?.split(",").length > 0 && e?.pimg?.split(",")?.map((el, ind, array) => (
                                                            <img src={PORTFOLIO_URL + el.replaceAll(" ", "")} className='img-fluid radius16' key={ind} alt="" />
                                                        ))
                                                    } */}
                                                    {/* <img src={require("../../../assets/img/home/portfolio/hospitality/features/Hospitality.png")} alt="" className='img-fluid radius16' /> */}
                                                </div>
                                                <div className='textGrpOverlap pt30 pb15 pl10 pr10 position-absolute'>

                                                    <Fade bottom triggerOnce={true}>
                                                        <h3 className='fs20 fw700 colorWhite '>    {e?.title}
                                                        </h3>
                                                    </Fade>

                                                    <p className='fs12 colorWhite'>
                                                        {e?.descp}
                                                    </p>

                                                </div>
                                            </div>

                                        </Col>
                                        <Col lg={12}>
                                            <div className='bgBlue radius16 d-flex justify-content-center align-items-center h-100'>
                                                <div className='logoGroup pt30 pb30 pl10 pr10 d-flex justify-content-between align-items-center'>
                                                    {
                                                        e?.logo_image.length > 0 && e?.logo_image?.map((el, ind, array) => (
                                                            el?.logo !== "" &&
                                                            <div className={`${ind !== array.length - 1 ? "mr20" : ""} logoList`} key={ind}>
                                                                <Fade bottom triggerOnce={true}>
                                                                    <a href={el?.url} target="_blank" rel="noopener noreferrer">
                                                                        <img src={PORTFOLIO_URL + el?.logo} className='img-fluid' alt="" />
                                                                    </a>
                                                                </Fade>
                                                            </div>
                                                        ))
                                                    }

                                                    {/* <div className='logoList mr20'>
                                                        <Fade bottom triggerOnce={true}>
                                                            <a href="https://www.instagram.com/toscafecocktails" target="_blank" rel="noopener noreferrer">
                                                                <img src={require("../../../assets/img/home/portfolio/hospitality/tos.png")} className='img-fluid' alt="" />
                                                            </a>
                                                        </Fade>
            
                                                    </div>
                                                    <div className='logoList mr20'>
                                                        <Fade bottom triggerOnce={true}>
                                                            <a href="https://www.instagram.com/nohahyd" target="_blank" rel="noopener noreferrer">
                                                                <img src={require("../../../assets/img/home/portfolio/hospitality/Noha.jpg")} className='img-fluid' alt="" />
                                                            </a>
                                                        </Fade>
                                                    </div>
                                                    <div className='logoList'>
                                                        <Fade bottom triggerOnce={true}>
                                                            <a href="https://www.instagram.com/aromaofdakshin" target="_blank" rel="noopener noreferrer">
                                                                <img src={require("../../../assets/img/home/portfolio/hospitality/aroma.png")} className='img-fluid' alt="" />
                                                            </a>
                                                        </Fade>
                                                    </div> */}
                                                </div>
                                            </div>


                                        </Col>
                                    </Row>
                                </div>

                            </div>
                        </Fade>
                    }
                    if (e.type === "left") {
                        return <div className='mobView mb60'>
                            <Row className='gx-2'>
                                <Col xs={6} sm={6} md={6} lg={6}>

                                    <div className='leftImage p5 bgGreyish radius16 zIndex1 position-relative'>
                                        <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>
                                            <Swiper
                                                spaceBetween={30}
                                                centeredSlides={true}
                                                autoplay={{
                                                    delay: 2500,
                                                    disableOnInteraction: false,
                                                }}
                                                pagination={{
                                                    type: 'fraction',
                                                }}
                                                navigation={false}
                                                modules={[Autoplay, Pagination, Navigation]}
                                                className="mySwiper portfolioSwiper radius16"
                                            >

                                                {
                                                    e.pimg?.split(",").length > 0 && e.pimg?.split(",")?.map((el, ind, array) => (
                                                        getFileExtension(el.replaceAll(" ", "")) === "mp4" ? <SwiperSlide key={ind}>
                                                            <Player
                                                                className="w-100" height={350} muted={true} playsInline={true} autoPlay={true} loop={true} controls={false}>
                                                                <source src={PORTFOLIO_URL + el.replaceAll(" ", "")} />
                                                            </Player>
                                                        </SwiperSlide> :
                                                            <SwiperSlide key={ind}>
                                                                <img src={PORTFOLIO_URL + el.replaceAll(" ", "")} alt="" className='img-fluid radius16' />
                                                            </SwiperSlide>
                                                    ))

                                                }

                                                {/* <SwiperSlide>
                                                <img src={require("../../../assets/img/home/portfolio/healthcare/features/health1.webp")} alt="" className='img-fluid radius16' />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img src={require("../../../assets/img/home/portfolio/healthcare/features/health2.webp")} alt="" className='img-fluid radius16' />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img src={require("../../../assets/img/home/portfolio/healthcare/features/health3.webp")} alt="" className='img-fluid radius16' />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <img src={require("../../../assets/img/home/portfolio/healthcare/features/health4.webp")} alt="" className='img-fluid radius16' />
                                            </SwiperSlide> */}

                                            </Swiper>


                                        </Fade>
                                    </div>


                                </Col>

                                <Col xs={6} sm={6} md={6} lg={6}>
                                    <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>
                                        <h3 className='fs20 fw700 colorBlack mb2'> {e?.title}
                                        </h3>
                                        <p className='fs12'>
                                            {e?.descp}
                                        </p>
                                    </Fade>

                                </Col>
                            </Row>
                            <Fade direction="up" duration={1000} delay={100} triggerOnce={true}>
                                <div className='bgBlue radius20 bgBlueMobView d-flex align-items-center justify-content-center'>
                                    <div className='logoGroup pl10 pr10 d-flex justify-content-center align-items-center'>

                                        {
                                            e?.logo_image.length > 0 && e?.logo_image?.map((el, ind, array) => (
                                                el?.logo !== "" &&
                                                <div className={`logoList text-center`} key={ind}>
                                                    <Fade bottom triggerOnce={true}>
                                                        <a href={el?.url} target="_blank" rel="noopener noreferrer">
                                                            <img src={PORTFOLIO_URL + el?.logo} className='img-fluid' alt="" />
                                                        </a>
                                                    </Fade>
                                                </div>
                                            ))
                                        }

                                        {/* <div className='logoList text-center'>
                                        <Fade bottom triggerOnce={true}>
                                            <a href="https://vihaaramedicare.com/" target="_blank" rel="noopener noreferrer">
                                                <img src={require("../../../assets/img/home/portfolio/healthcare/vihara.png")} className='img-fluid' alt="" />
                                            </a>
                                        </Fade>
        
                                    </div>
                                    <div className='logoList text-center'>
                                        <Fade bottom triggerOnce={true}>
                                            <a href="https://vasavihospital.in/" target="_blank" rel="noopener noreferrer">
                                                <img src={require("../../../assets/img/home/portfolio/healthcare/vasavi.png")} className='img-fluid' alt="" />
                                            </a>
                                        </Fade>
        
                                    </div> */}

                                    </div>
                                </div>
                            </Fade>

                        </div>
                    }
                })
            }





        </div>
    )
}

export default PortfolioMobile