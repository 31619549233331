export const HOME_URL = "https://didige.in/webcommon/api/"
export const PARTNER_LIST = HOME_URL+"partnerList"
export const PORTFOLIO_LIST = HOME_URL+"portfolioList"
export const CONTACT_FORM = HOME_URL+"contactApiForAdmin"
export const PARTNER_URL= "https://didige.in/partners/"
export const PORTFOLIO_URL= "https://didige.in/portfolio/"

export const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  };

 export  const optionsPost = {
    method: "POST",
   headers: {
        // if file upload "Content-Type": "multipart/form-data",
        Accept: "application/json",
        // Authorization: "Bearer " + Auth.getToken(),
      },
  };