import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import axios from "axios";
import { PARTNER_LIST, PARTNER_URL, PORTFOLIO_URL, options } from "../../helpers/apiurls";
import { Fade, Slide } from "react-awesome-reveal";

let data = [
  {
    title: "Sri Aditya Homes",
    img: require("../../assets/img/home/partner/new/1.jpg"),
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    title: "Trendset Jayabheri",
    img: require("../../assets/img/home/partner/new/2.jpg"),
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    title: "Key Pixel",
    img: require("../../assets/img/home/partner/new/3.jpg"),
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
];


const Partners = () => {

  const [loader, setloader] = useState(true);
  const [partnerList, setPartnerList] = useState([]);

  useEffect(() => {
    partners();
  }, []);
  const partners = async () => {
    await axios.get(PARTNER_LIST, options).then((res) => {
      if (res && res.status === 200) {
        setPartnerList(res?.data);
        setloader(false);
      }
    });
  };

  return (
    <section
      id="partners"
      className="bgWhite pt80 pb60"
    >

      <div className="wrapper_what_people w-100">
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="wrapperWhole radius50">
                <Fade direction="up" duration={1000} delay={200} triggerOnce={true}>
                  <div className="uppercaseSec">
                    <h2 className="colorBlack mb0 fs48 fw700 mt10 text-center">
                      Our Partners
                    </h2>
                  </div>
                </Fade>


                {
                  loader ?
                    <div className="skeleton m-5" style={{ height: "300px" }}></div> :
                    <div className="mobPlr0 position-relative">
                      <Swiper
                        modules={[Autoplay, Pagination, Navigation]}
                        centeredSlides={true}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false,
                        }}
                        pagination={{
                          clickable: true,
                        }}
                        navigation={false}
                        className="mySwiper dotscss radiusB16"
                      >
                        <div className="bloggerList">
                          {partnerList.length > 0 && partnerList?.map((e, i) => (
                            <SwiperSlide key={i}>
                              <div className="partnerInnWrapper">

                                <Row className="justify-content-center mb30 pl15 pr15">
                                  <Col lg={10}>
                                    <div className="textWrapper text-center">
                                      <Fade direction="up" duration={1000} delay={200} triggerOnce={true}>
                                        <h3 className="fs36 fw500 colorBlack text-uppercase">
                                          {e?.title}
                                        </h3>
                                      </Fade>

                                      <Fade direction="up" duration={1000} delay={300} triggerOnce={true}>
                                        <p className="fs17 fw400 lightPara mb0">
                                          {e?.descp}
                                        </p>
                                      </Fade>


                                    </div>
                                  </Col>
                                </Row>

                                <div className="imgWrapper radiusB16">
                                  <img src={PARTNER_URL + e?.partner_img} className="img-fluid w-100" alt="partners" />
                                </div>

                              </div>



                            </SwiperSlide>
                          ))}
                        </div>
                      </Swiper>
                      {/* <span className="shake">Click to Next</span> */}
                    </div>
                }


              </div>
            </Col>
          </Row>

        </Container>

      </div>
    </section>
  );
};

export default Partners;
