import React, { useEffect, useMemo, useState } from "react";
import HomeBanner from "./HomeBanner";
import "../../assets/css/home.css";
import { Helmet } from "react-helmet";
import AboutUs from "./AboutUs";
import { useSelector } from "react-redux";
import { useRef } from "react";
import Partners from "./Partners";
import Portfolio from "./portfolio/Portfolio";

const HomePage = () => {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  });

  var myRef = useRef(null);
  const [slideOpen, setslideOpen] = useState(false);
  const viewPort = useSelector((state) => {
    return state && state?.persistedReducer?.Slider?.viewPort;
  });
  const homeAnimate = useSelector((state) => {
    return state && state?.persistedReducer?.theme?.flip;
  });

  return (
    <>
      <Helmet>
        <title>Home - Archana Didige Group</title>
      </Helmet>
      <main>
        <HomeBanner />
        <AboutUs />

        {/* <button
          onClick={() => executeScroll()}
          id="makeClick"
          className="d-none"
        >
          Click to scroll
        </button> */}
        {/* <OurProjects /> */}


        {/* {
          screenWidth > 1200 ? <ScrollComp /> : <WhyChooseUs />
        } */}
        {/* <CustomSlider/> */}
        {/* <Vert2/> */}
        {/* <WhyChooseUs /> */}
        {/* <VerticalSl/>  */}



        {/* {viewPort && (
          <div ref={myRef && myRef}>
            <WhoWeAre />
            <Testimonial />
          </div>
        )} */}

        <Portfolio />

        <Partners />
      </main>
    </>
  );
};

export default HomePage;
