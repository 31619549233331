/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "../../assets/css/footer.css";
import { useDispatch, useSelector } from "react-redux";
import { Fade, Zoom } from "react-awesome-reveal";
import { viewPortFunc } from "../../store/slices/SliderReducer";
import { HashLink } from "react-router-hash-link";
import ContactForm from "./ContactForm";
import { callIcon, copyright, enevelop, mapPin } from "../../assets/svg/Svg";
import AlertModal from "../AlertModal/AlertModal";
import PrivacyPolicy from "../../pages/privacyPolicy";
import TermsConditions from "../../pages/termsConditions";

const Footer = () => {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  }, []);

  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    window.addEventListener("load", function () {
      dispatch(viewPortFunc(false));
    });
  }, []);

  return (
    <>
      <footer
        className={`position-relative  h-100 d-flex align-items-end  greyGradient`}
      >
        <div
          className="footerSec w-100"
          style={{
            backgroundImage: `url(${
              screenWidth > 767 && require("../../assets/img/footer_bg.png")
            })`,
          }}
        >
          <Container>
            <Row>
              <Col lg={6} md={6} xl={6}></Col>

              <Col lg={6} md={6} xl={6}>
                <ContactForm />

                <div className="contactInfo pt60 pb60">
                  <Fade direction="up" duration={1500} delay={100} triggerOnce={true}>
                    <div className="d-flex align-items-center mb20">
                      <div className="mr10">{mapPin}</div>
                      <p className="fs18 fw400 colorBlack mb0">
                        Plot no. 1148, ayyappa society main road, Madhapur ,
                        Hyderabad 500081
                      </p>
                    </div>
                  </Fade>
                  <Fade direction="up" duration={1500} delay={100} triggerOnce={true}>
                  <Row className="mb20">
                    {/* <Col lg={6}>
                    <a href="tel:+1 202-918-2132">
                      <div className="d-flex align-items-center">
                        <div className="mr10">
                          {callIcon}
                        </div>
                        <p className="fs18 fw400 colorBlack mb0">
                          +1 202-918-2132
                        </p>
                      </div>
                    </a>
                  </Col> */}

                    <Col lg={6}>
                      <a href="mailto:info@didige.in">
                        <div className="d-flex align-items-center">
                          <div className="mr10">{enevelop}</div>
                          <p className="fs18 fw400 colorBlack mb0">
                            info@didige.in
                          </p>
                        </div>
                      </a>
                    </Col>
                  </Row>
                  </Fade>
             
                  <Fade direction="up" duration={1500} delay={100} triggerOnce={true}>


                  <div className="d-flex align-items-center flex-wrap">
                    <div className="d-flex align-items-center mr10">
                      <div className="mr10">{copyright}</div>
                      <p className="fs15 fw400 colorBlack mb0">
                        {new Date().getFullYear()}. All Rights Reserved
                      </p>
                    </div>
                    <div className="d-flex align-items-center mr10">
                      <p
                        className="fs15 fw400 colorBlack mb0 pointer"
                        onClick={() =>
                          AlertModal.show(<PrivacyPolicy />, "", () => {}, "lg")
                        }
                      >
                        Terms & Conditions
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p
                        className="fs15 fw400 colorBlack mb0 pointer"
                        onClick={() =>
                          AlertModal.show(
                            <TermsConditions />,
                            "",
                            () => {},
                            "lg"
                          )
                        }
                      >
                        Privacy Policy
                      </p>
                    </div>
                  </div>
                  </Fade>
                </div>
              </Col>
            </Row>
          </Container>

                   {screenWidth < 767 && (
            <img
              src={require("../../assets/img/footer_bg.png")}
              className="img-fluid"
              alt=""
            />

          
          
          )}  
       
        </div>
      </footer>
    </>
  );
};

export default Footer;
